import React, { Component } from "react";
import { Link } from "react-router-dom";
export class Sitemap extends Component {
  static displayName = Sitemap.name;

  render() {
    const script = document.createElement("script");

    script.body = `function quickbox() {
      if ($(window).width() > 767) {
        $(".quickview").magnificPopup({
          type: "iframe",
          delegate: "a",
          preloader: true,
          tLoading: "Loading image #%curr%...",
        });
      }
    }
    jQuery(document).ready(function () {
      quickbox();
    });
    jQuery(window).resize(function () {
      quickbox();
    });`;

    document.body.appendChild(script);
    return (
      <div className="mt-4">
        <div id="information-sitemap" class="container">
          <h2 class="page-title">Site Map</h2>
          <div class="row site-map">
            <div class="col-sm-6 sitemap-left">
              <ul>
                <li>
                  <a href="route=product/category&amp;path=0">All Products</a>
                </li>
                <li>
                  <a href="route=product/category&amp;path=34">our store</a>

                  <ul>
                    <li>
                      <a href="route=product/category&amp;path=34_43">
                        audio &amp; video
                      </a>
                      <ul>
                        <li>
                          <a href="route=product/category&amp;path=34_43_44">
                            temporibus autem
                          </a>
                        </li>
                        <li>
                          <a href="route=product/category&amp;path=34_43_47">
                            ullamco laboris{" "}
                          </a>
                        </li>
                        <li>
                          <a href="route=product/category&amp;path=34_43_48">
                            voluptatem sequi
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="route=product/category&amp;path=34_49">
                        hair dryers
                      </a>
                      <ul>
                        <li>
                          <a href="route=product/category&amp;path=34_49_50">
                            dictum gravida
                          </a>
                        </li>
                        <li>
                          <a href="route=product/category&amp;path=34_49_51">
                            rem aperiam
                          </a>
                        </li>
                        <li>
                          <a href="route=product/category&amp;path=34_49_52">
                            ridiculus mus
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="route=product/category&amp;path=34_53">
                        headphones
                      </a>
                      <ul>
                        <li>
                          <a href="route=product/category&amp;path=34_53_54">
                            accusamus iusto{" "}
                          </a>
                        </li>
                        <li>
                          <a href="route=product/category&amp;path=34_53_55">
                            nulla venenatis
                          </a>
                        </li>
                        <li>
                          <a href="route=product/category&amp;path=34_53_56">
                            Phasellus tempu
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="route=product/category&amp;path=20">Desktops</a>

                  <ul>
                    <li>
                      <a href="route=product/category&amp;path=20_26">PC</a>
                    </li>
                    <li>
                      <a href="route=product/category&amp;path=20_27">Mac</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="route=product/category&amp;path=18">
                    Laptops &amp; Notebooks
                  </a>

                  <ul>
                    <li>
                      <a href="route=product/category&amp;path=18_46">Macs</a>
                    </li>
                    <li>
                      <a href="route=product/category&amp;path=18_45">
                        Windows
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="route=product/category&amp;path=25">Components</a>

                  <ul>
                    <li>
                      <a href="route=product/category&amp;path=25_29">
                        Mice and Trackballs
                      </a>
                    </li>
                    <li>
                      <a href="route=product/category&amp;path=25_28">
                        Monitors
                      </a>
                    </li>
                    <li>
                      <a href="route=product/category&amp;path=25_30">
                        Printers
                      </a>
                    </li>
                    <li>
                      <a href="route=product/category&amp;path=25_31">
                        Scanners
                      </a>
                    </li>
                    <li>
                      <a href="route=product/category&amp;path=25_32">
                        Web Cameras
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="route=product/category&amp;path=57">Tablets</a>
                </li>
                <li>
                  <a href="route=product/category&amp;path=17">Software</a>
                </li>
                <li>
                  <a href="route=product/category&amp;path=24">
                    Phones &amp; PDAs
                  </a>
                </li>
                <li>
                  <a href="route=product/category&amp;path=33">Cameras</a>
                </li>
              </ul>
            </div>
            <div class="col-sm-6 sitemap-right">
              <ul>
                <li>
                  <a href="route=product/special">Special Offers</a>
                </li>
                <li>
                  <a href="route=account/account">My Account</a>
                  <ul>
                    {/* <li><a href="route=account/edit">Account Information</a></li> */}
                    <li>
                      <a href="https://backoffice.infinitemlmsoftware.com/backoffice/">
                        Goto Backoffice
                      </a>
                    </li>
                    <li>
                      <a href="route=account/wishlist">Wish List (%s)</a>
                    </li>
                    {/* <li><a href="route=account/password">Password</a></li> */}
                    {/* <li><a href="route=account/address">Address Book</a></li> */}
                    {/* <li><a href="route=account/order">Order History</a></li> */}
                    <li>
                      <a href="route=account/download">Downloads</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="route=account/order">Shopping Cart</a>
                </li>
                <li>
                  <a href="route=checkout/checkout">Checkout</a>
                </li>
                <li>
                  <a href="route=product/search">Search</a>
                </li>
                <li>
                  Information
                  <ul>
                    <li>
                      <a href="route=information/information&amp;information_id=4">
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href="route=information/information&amp;information_id=6">
                        Delivery Information
                      </a>
                    </li>
                    <li>
                      <a href="route=information/information&amp;information_id=3">
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a href="route=information/information&amp;information_id=5">
                        Terms &amp; Conditions
                      </a>
                    </li>
                    <li>
                      <a href="route=information/contact">Contact Us</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
