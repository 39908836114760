import React, { Component } from "react";
import { logo } from "src/assets/icons/logo";
import { Form, Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactWOW from "react-wow";

export class ContactUs extends Component {
  static displayName = ContactUs.name;

  render() {
    return (
      <div className="p-5 color-bg-two" >
        <div className="rounded row bg-white shadow" data-aos="zoom-in-up">
          <div className="col-lg-4 color-bg-one rounded p-5 wow bounceInUp">
            <h3 className="text-white">Contact Us</h3>
            <p className="text-white">We would love to hear from you...</p>
            <div className="text-white">
              <label>
                <FontAwesomeIcon icon="location-arrow" className="mr-1" />
                Address
              </label>
              <p className="ml-3">
                BUSSINZILLA PRIVATE LIMITED, Shop no.2, Main Chowk, VPO KIRTAN, Hisar, Haryana - 125001
              </p>
            </div>
            <div className="text-white flex-column">
              <label>
                <FontAwesomeIcon icon="phone-alt" className="mr-1" />
                Phone Number
              </label>
              <br />
              <a className="text-white force-hover ml-3" href="tel:+91-9999955555">
                +91-9468199599{" "}
              </a>
            </div>
            <div className="text-white">
              <label>
                <FontAwesomeIcon icon="envelope" className="mr-1" />
                Email
              </label>
              <br />
              <a
                className="text-white force-hover ml-3"
                href="mailto:info.bussinzilla@gmail.com"
              >
                info.bussinzilla@gmail.com{" "}
              </a>
            </div>
          </div>
          <div className="col-lg-8 rounded p-5">
            <h2 className="color-one">Submit your details here...</h2>
            <Form className="w-100 ml-auto">
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Name" />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Email" />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="phonenumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="text" placeholder="Phone Number" />
                <Form.Text className="text-muted">
                  We'll never share your phone number with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="phonenumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control as="textarea" row={5} placeholder="Message" />
              </Form.Group>
              <Button
                className="color-bg-one color-border-one button-hover text-white"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
