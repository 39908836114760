import React, { useContext, useEffect, useState } from "react";
import { ApiFetch } from "../assets/FetchApi";
import { Redirect, useHistory } from "react-router-dom";
import { Card, Button, Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppContext } from "src/context";
import { Itemloader } from "src/Preloader";
export default function ProductDetail({ location }) {
  const { appUser } = useContext(AppContext);
  const { state } = location;
  const history = useHistory();
  const [ActiveImage, setActiveImage] = useState("");
  const [response, setResponse] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [pQuantity, setPQuantity] = useState(1);
  const [images, setImages] = useState([]);
  const [toastShow, setToastShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [id, setId] = useState(0);

  function loadData(id) {
    setPQuantity(1);
    ApiFetch("User/GetProductById?id=" + id)
      .then((res) => {
        if (res.status === 200) {
          res.json().then((result) => {
            setResponse(result.product);
            setImages(result.product.imageUrls);
            setActiveImage(
              result.product.imageUrls.length
                ? result.product.imageUrls[0]
                : result.product.imageUrl
            );
            setRelatedProducts(result.relatedProducts);
          });
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }
  useEffect(() => {
    if (state != undefined) {
      setId(state.id);
      loadData(state.id);
    }
  }, []);
  function addToCart(id, quantity) {
    ApiFetch(
      "User/AddToCart?id=" + id + "&quantity=" + quantity,
      appUser.token
    ).then((res) => {
      if (res.status === 200) {
        setToastShow(true);
      }
    });
  }
  function buyNow() {
    addToCart(response.id, pQuantity);
    history.push("/CheckOut");
  }
  if (state === undefined) {
    return (
      <Redirect
        to={{
          pathname: "/Products",
        }}
      />
    );
  }
  return (
    <div className="container mt-4 mb-4">
      {isLoading ? <Itemloader /> : null}
      <Toast
        className="position-fixed"
        style={{ zIndex: 9999, top: 10, right: 10 }}
        delay={2000}
        autohide
        show={toastShow}
        onClose={() => setToastShow(false)}
      >
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="mr-auto">Alert !</strong>
        </Toast.Header>
        <Toast.Body>Item added to cart!</Toast.Body>
      </Toast>
      <div className="row m-0">
        <div className="col-6">
          <div
            style={{ height: 400 }}
            className="col-7 d-flex justify-content-center align-items-center img-zoom"
            data-wow-duration="2s"
            data-wow-delay="5s"
          >
            <img src={"/Thumbnails/" + ActiveImage} className="" height={300} />
          </div>
          <div
            className="d-flex col-5 scrollbar-hidden"
            style={{ maxWidth: 500, overflow: "auto" }}
          >
            {images.map((item) => (
              <div className="d-flex justify-content-center align-items-center mb-3">
                <button
                  className="bg-transparent border-danger mr-2"
                  onClick={() => setActiveImage(item)}
                >
                  <img
                    src={"/Thumbnails/" + item}
                    height={65}
                    className="w-auto"
                  />
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="col-lg-6">
          <h3 className="mt-5">{response.name}</h3>
          <div className="mb-3">{}</div>
          <div className="mb-3">
            MRP:{" "}
            <span style={{ textDecoration: "line-through" }}>
              ₹ {response.mrp}
            </span>
          </div>
          <div className="mb-3">
            Price: <span>₹ {response.price}</span>
          </div>
          <div className="mb-3 border-bottom">{response.description}</div>
          <div className="mb-4 mt-4">
            Business Valuse: <span>{response.bv}</span>
          </div>
          <div className="mb-4 mt-4">
            Quantity:
            <button
              onClick={() => setPQuantity(pQuantity - 1)}
              disabled={pQuantity <= 1 ? true : false}
              className="bg-transparent"
              style={{ borderColor: "#d32a49", marginLeft: 10 }}
            >
              <FontAwesomeIcon icon="minus" color={"#d32a49"} />
            </button>{" "}
            <input
              className="border p-2 focus"
              disabled={true}
              style={{ width: 30 }}
              value={pQuantity}
              onChange={(e) =>
                (e.target.value <= response.quantity && e.target.value > 0) ||
                e.target.value === ""
                  ? setPQuantity(e.target.value)
                  : (alert(
                      "max quantity possible " +
                        response.quantity +
                        " and minimum quantity required 1"
                    ),
                    setPQuantity(1))
              }
            />
            <button
              onClick={() => setPQuantity(pQuantity + 1)}
              disabled={response.quantity <= pQuantity ? true : false}
              className="bg-transparent"
              style={{ borderColor: "#d32a49", marginLeft: 10 }}
            >
              <FontAwesomeIcon icon="plus" color={"#d32a49"} />
            </button>{" "}
          </div>
          <div className="mt-3">
            <Button
              className="color-bg-one color-border-one button-hover"
              onClick={() => {
                appUser.isLoggedIn == true || appUser.isLoggedIn == "true"
                  ? buyNow()
                  : history.push("Login");
              }}
            >
              Buy Now
            </Button>
            <Button
              className="color-bg-one color-border-one button-hover ml-3"
              onClick={() => {
                appUser.isLoggedIn == true || appUser.isLoggedIn == "true"
                  ? addToCart(response.id, pQuantity)
                  : history.push("Login");
              }}
            >
              Add to cart
            </Button>
          </div>{" "}
        </div>
      </div>
      <div className="mt-4">
        <h4 class="page-title text-center mb-4">Related Products</h4>
        <div className="justify-content-center d-flex m-auto">
          <div className="row justify-content-evenly gap col-12">
            {relatedProducts.map((item) => (
              <Card style={{ width: "15rem" }}>
                <div className="align-items-center justify-content-center d-flex">
                  <Card.Img
                    variant="top"
                    src={"/Thumbnails/" + item.imageUrl}
                    className="p-3 w-auto"
                    height={150}
                  />
                </div>
                <Card.Body>
                  <Card.Title className="h6">{item.name}</Card.Title>
                  <div>
                    <span>MRP: ₹ {item.mrp}</span>
                  </div>
                  <div>
                    <span>Saling Price: ₹ {item.price} </span>
                  </div>
                  <div>
                    <span>BV: {item.bv}</span>
                  </div>
                  <div className="mt-2">
                    <Button
                      className="color-bg-one color-border-one button-hover"
                      onClick={() => {
                        setId(item.id);
                        loadData(item.id);
                      }}
                    >
                      View More
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
