import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, Button } from "react-bootstrap";

import image1 from "../assets/Image/products/1.jpg";
import image2 from "../assets/Image/products/2.jpg";
import image3 from "../assets/Image/products/3.jpg";
import image4 from "../assets/Image/products/4.jpg";
import image5 from "../assets/Image/products/7.png";
import image6 from "../assets/Image/products/6.png";

export class AboutUs extends Component {
  static displayName = AboutUs.name;

  render() {
    return (
      <div className="mt-4">
        {/* Marketing messaging and featurettes
================================================== */}
        {/* Wrap the rest of the page in another container to center all the content. */}

        <div class="container marketing">
          {/* START THE FEATURETTES */}

          <hr class="featurette-divider" />

          <div class="row featurette">
            <div class="col-md-7">
              <h2 class="featurette-heading">Who we are ?</h2>
              <p class="lead">
                Donec ullamcorper nulla non metus auctor fringilla. Vestibulum
                id ligula porta felis euismod semper. Praesent commodo cursus
                magna, vel scelerisque nisl consectetur. Fusce dapibus, tellus
                ac cursus commodo.
              </p>
            </div>
            <div class="col-md-5">
              <img
                class="featurette-image img-fluid mx-auto"
                src={image4}
                alt="Who we are ?"
              />
            </div>
          </div>

          <hr class="featurette-divider" />

          <div class="row featurette">
            <div class="col-md-7 order-md-2">
              <h2 class="featurette-heading">What we do ?</h2>
              <p class="lead">
                Donec ullamcorper nulla non metus auctor fringilla. Vestibulum
                id ligula porta felis euismod semper. Praesent commodo cursus
                magna, vel scelerisque nisl consectetur. Fusce dapibus, tellus
                ac cursus commodo.
              </p>
            </div>
            <div class="col-md-5 order-md-1">
              <img
                class="featurette-image img-fluid mx-auto"
                src={image2}
                alt="What we do ?"
              />
            </div>
          </div>

          <hr class="featurette-divider" />

          <div class="row featurette">
            <div class="col-md-7">
              <h2 class="featurette-heading">Why we do ?</h2>
              <p class="lead">
                Donec ullamcorper nulla non metus auctor fringilla. Vestibulum
                id ligula porta felis euismod semper. Praesent commodo cursus
                magna, vel scelerisque nisl consectetur. Fusce dapibus, tellus
                ac cursus commodo.
              </p>
            </div>
            <div class="col-md-5">
              <img
                class="featurette-image img-fluid mx-auto"
                src={image1}
                alt="Why we do ?"
              />
            </div>
          </div>

          <hr class="featurette-divider" />

          {/* /END THE FEATURETTES */}
          {/* Three columns of text below the carousel */}
          <div class="row">
            <div class="col-lg-4" data-aos="fade-up">
              <img
                class="rounded-circle"
                src={image3}
                alt="Generic placeholder image"
                width="140"
                height="140"
              />
              <h2>Heading</h2>
              <p>
                Donec sed odio dui. Etiam porta sem malesuada magna mollis
                euismod. Nullam id dolor id nibh ultricies vehicula ut id elit.
                Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                Praesent commodo cursus magna.
              </p>
              <p>
                <button
                  class="btn btn-secondary color-bg-one text-white border-0 button-hover"
                  type="button"
                  role="button"
                >
                  View details &raquo;
                </button>
              </p>
            </div>
            {/* /.col-lg-4 */}
            <div class="col-lg-4" data-aos="fade-up">
              <img
                class="rounded-circle"
                src={image5}
                alt="Generic placeholder image"
                width="140"
                height="140"
              />
              <h2>Heading</h2>
              <p>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula,
                eget lacinia odio sem nec elit. Cras mattis consectetur purus
                sit amet fermentum. Fusce dapibus, tellus ac cursus commodo,
                tortor mauris condimentum nibh.
              </p>
              <p>
                <button
                  class="btn btn-secondary color-bg-one text-white border-0 button-hover"
                  type="button"
                  role="button"
                >
                  View details &raquo;
                </button>
              </p>
            </div>
            {/* /.col-lg-4 */}
            <div class="col-lg-4" data-aos="fade-up">
              <img
                class="rounded-circle"
                src={image6}
                alt="Generic placeholder image"
                width="140"
                height="140"
              />
              <h2>Heading</h2>
              <p>
                Donec sed odio dui. Cras justo odio, dapibus ac facilisis in,
                egestas eget quam. Vestibulum id ligula porta felis euismod
                semper. Fusce dapibus, tellus ac cursus commodo, tortor mauris
                condimentum nibh, ut fermentum massa justo sit amet risus.
              </p>
              <p>
                <button
                  class="btn btn-secondary color-bg-one text-white border-0 button-hover"
                  type="button"
                  role="button"
                >
                  View details &raquo;
                </button>
              </p>
            </div>
            {/* /.col-lg-4 */}
          </div>
          {/* /.row */}
        </div>
      </div>
    );
  }
}
