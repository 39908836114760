import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-wow";
export class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <ListGroup
          horizontal={true}
          className="flex-column container border-0 flex-lg-row"
        >
          <ListGroup.Item className="col-lg-3 border-0 bg-transparent">
            <div className="rounded">
              <h3 className="h4 mt-4 pb-2">Contact Us</h3>
              <div className="text-dark">
                <label>
                  <FontAwesomeIcon icon="location-arrow" className="mr-1" />
                  Address
                </label>
                <p className="ml-3">
                  BUSSINZILLA PRIVATE LIMITED, Shop no.2, Main Chowk, VPO
                  KIRTAN, Hisar, Haryana - 125001
                </p>
              </div>
              <div className="text-dark flex-column">
                <label>
                  <FontAwesomeIcon icon="phone-alt" className="mr-1" />
                  Phone Number
                </label>
                <br />
                <a className="ml-3" href="tel:+91-9999955555">
                  +91-9468199599{" "}
                </a>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item className="col-lg-3 border-0 bg-transparent">
            <div className="h4 mt-4 pb-2 pl-lg-4">Company</div>
            <ul className="pl-lg-5 li-gap">
              <li>
                <Link to="/">About Us</Link>
              </li>
              <li>
                {" "}
                <Link to="/">Our Service</Link>
              </li>
              <li>
                {" "}
                <Link to="/">Privacy Policy</Link>
              </li>
              <li>
                {" "}
                <Link to="/">Affilate Program</Link>
              </li>
            </ul>
          </ListGroup.Item>
          <ListGroup.Item className="col-lg-3 border-0 bg-transparent">
            <div className="h4 mt-4 pb-2">Get Help</div>
            <ul className="pl-4 li-gap">
              <li>
                <Link to="/">Shopping</Link>
              </li>
              <li>
                {" "}
                <Link to="/">Return</Link>
              </li>
              <li>
                {" "}
                <Link to="/">Order Status</Link>
              </li>
              <li>
                {" "}
                <Link to="/">Payment Options</Link>
              </li>
            </ul>
          </ListGroup.Item>
          <ListGroup.Item className="col-lg-3 border-0 bg-transparent">
            <div className="h4 mt-4 pb-2">NewsLetter</div>
            <form className="form-footer">
              <i class="fas fa-envelope"></i>
              <input
                className="input-footer"
                type="email"
                placeholder="Enter your email id"
                required
              />
              <button className="btnh" type="submit">
                <i class="fas fa-arrow-right"></i>
              </button>
            </form>
            <div className="text-dark">
              <label>
                <FontAwesomeIcon icon="envelope" className="mr-1" />
                Email
              </label>
              <br />
              <a className="ml-3" href="mailto:info.bussinzilla@gmail.com">
                info.bussinzilla@gmail.com{" "}
              </a>
            </div>
            <ListGroup horizontal className="bg-transparent">
              <ListGroup.Item className="border-0 bg-transparent">
                <a href="#">
                  <FontAwesomeIcon
                    className="social-iconss color-two-hover"
                    icon={["fab", "facebook"]}
                    size={25}
                  />
                </a>
              </ListGroup.Item>
              <ListGroup.Item className="border-0 bg-transparent">
                <a href="#">
                  <FontAwesomeIcon
                    className="social-iconss color-two-hover"
                    icon={["fab", "twitter"]}
                  />
                </a>
              </ListGroup.Item>
              <ListGroup.Item className="border-0 bg-transparent">
                <a href="#">
                  <FontAwesomeIcon
                    className="social-iconss color-two-hover"
                    icon={["fab", "instagram"]}
                  />
                </a>
              </ListGroup.Item>
            </ListGroup>
          </ListGroup.Item>
        </ListGroup>
        <div className="d-flex py-3 border-top px-3">
          <div>
            <a
              href="https://bussinzilla.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bussinzilla
            </a>
            <span className="ml-1">
              &copy; {new Date().getFullYear()} All rights reserved.
            </span>
          </div>
          <div className="mfs-auto">
            <span className="mr-1">Developed by</span>
            <a
              href="https://quickapptechnologies.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Quick App Technologies
            </a>
          </div>
        </div>
      </div>
    );
  }
}
