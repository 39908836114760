import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { logo } from "src/assets/icons/logo";
import { AppContext } from "src/context";
import { ApiFetch } from "../assets/FetchApi";

const Register = () => {
  const history = useHistory();
  const [fullname, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [sponsorId, setSponsorId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const handleRegister = () => {
    ApiFetch("Account/UserRegister", null, {
      FullName: fullname,
      Email: username,
      PhoneNumber: phonenumber,
      Password: password,
      SponsorId: sponsorId != "" ? sponsorId.replace("BZ", "") : 0,
    })
      .then((res) => {
        if (res.status === 200) {
          alert("Registration Success");
          res.json().then((result) => {
            history.push("ConfirmAccount", { username: result.userName });
          });
        } else if (res.status === 441) {
          res.json().then((result) => {
            if (result != null && result != undefined && result != "") {
              alert(result);
            }
          });
        } else {
          alert("Something went wrong");
        }
      })
      .catch((error) => {});
  };
  return (
    <div className="mt-5 mb-5">
      <div className="container">
        <div className="justify-content-center align-items-center d-flex flex-column w-100">
          <div className="">
            {" "}
            <img width={120} src={logo} />{" "}
          </div>
          <div className="col-6">
            <form
              className=""
              id=""
              name=""
              autocomplete="off"
              method="post"
              accept-charset="utf-8"
              novalidate="novalidate"
            >
              <div className="text-danger  text-center" ng-show="authError">
                {" "}
              </div>

              <div className="list-group form-group login-input">
                <div className="list-group-item">
                  <input
                    value={fullname}
                    onChange={(val) => setFullname(val.target.value)}
                    type="text"
                    name="fullname"
                    autocomplete="Off"
                    placeholder="Full Name"
                    className="form-control no-border"
                  />
                </div>
                <div className="list-group-item">
                  <input
                    value={username}
                    onChange={(val) => setUsername(val.target.value)}
                    type="text"
                    name="username"
                    autocomplete="Off"
                    placeholder="Email"
                    className="form-control no-border"
                  />
                </div>
                <div className="list-group-item">
                  <input
                    value={phonenumber}
                    onChange={(val) => setPhoneNumber(val.target.value)}
                    type="text"
                    name="phonenumber"
                    autocomplete="Off"
                    placeholder="Phone Number"
                    className="form-control no-border"
                  />
                </div>
                <div className="list-group-item">
                  <input
                    value={sponsorId}
                    onChange={(val) => setSponsorId(val.target.value)}
                    type="text"
                    name="sponsorid"
                    autocomplete="Off"
                    placeholder="Sponsor Id"
                    className="form-control no-border"
                  />
                </div>
                <div className="list-group-item form-group">
                  <input
                    value={password}
                    onChange={(val) => setPassword(val.target.value)}
                    type="password"
                    name="password"
                    placeholder="New Password"
                    className="form-control no-border password"
                  />
                </div>
                <div className="list-group-item form-group">
                  <input
                    value={confirmpassword}
                    onChange={(val) => setConfirmPassword(val.target.value)}
                    type="password"
                    name="confirmpassword"
                    placeholder="Confirm Password"
                    className="form-control no-border password"
                  />
                </div>
              </div>
              <div className="">
                <input
                  onClick={handleRegister}
                  type="button"
                  name="UserRegister"
                  value="Register"
                  className="btn btn-lg color-bg-one text-white button-hover btn-block"
                />
              </div>

              <div className="m-t-md">
                <span id="msg"></span>
              </div>
              <p className="text-center p-3 link-fade">
                <small>Already have an account? </small>{" "}
                <Link className=" info-link" to="/Login">
                  Sign In
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Register;
