import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "src/context";
import { ApiFetch, FormApiFetch } from "../assets/FetchApi";

export default function Profile() {
  const { appUser } = useContext(AppContext);
  const [id, setId] = useState("");
  const [fullName, setFullName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [bank, setBank] = useState("");
  const [account, setAccount] = useState("");
  const [ifsc, setIFSC] = useState("");
  const [idProof, setIdProof] = useState([]);
  const [panProof, setPanProof] = useState([]);
  const [proof, setProof] = useState([]);
  const [image, setImage] = useState([]);
  const [networkId, setNetworkId] = useState("");
  const [sponsorId, setSponsorId] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isKYC, setIsKYC] = useState(null);
  const [proofUrl, setProofUrl] = useState("");
  const [idProofUrl, setIdProofUrl] = useState("");
  const [panProofUrl, setPanProofUrl] = useState("");
  const [fileName, setFileName] = useState("");
  function loadData() {
    setFileName("");
    ApiFetch("Account/GetProfileSettings", appUser.token)
      .then((res) => {
        if (res.status === 200) {
          res.json().then((result) => {
            setId(result.id);
            setFullName(result.fullName);
            setPhoneNumber(result.phoneNumber);
            setEmail(result.email);
            setUserName(result.userName);
            setAddress(result.address);
            setNetworkId(result.networkId);
            setSponsorId(result.sponsorId);
            setBank(result.bank);
            setAccount(result.account);
            setIFSC(result.ifsc);
            setImageUrl("/Thumbnails/" + result.imageUrl);
            setIsKYC(result.isKYC);
            setProofUrl(result.proofUrl);
            setIdProofUrl(result.idProofUrl);
            setPanProofUrl(result.panProofUrl);
          });
        }
      })
      .catch((error) => {})
      .finally(() => {});
  }
  useEffect(() => {
    loadData();
  }, []);
  function UpdateProfile() {
    var formData = new FormData();
    formData.append("Id", id);
    formData.append("FullName", fullName);
    formData.append("Email", email);
    formData.append("PhoneNumber", phoneNumber);
    formData.append("Address", address);
    formData.append("Bank", bank);
    formData.append("Account", account);
    formData.append("IFSC", ifsc);
    formData.append("IdProof", idProof);
    formData.append("PanProof", panProof);
    formData.append("Proof", proof);
    formData.append("Image", image);
    formData.append("OldPassword", oldPassword);
    formData.append("NewPassword", newPassword);
    FormApiFetch("Account/UpdateProfileSettings", appUser.token, formData).then(
      (res) => {
        if (res.status === 200) {
          loadData();
          alert("Updated Successfully");
        } else if (res.status === 402) {
          alert("Old password is wrong, please enter correct old password");
        }
      }
    );
  }
  function img_pathUrl(img) {
    if (img != undefined) {
      setImage(img);
      setImageUrl(URL.createObjectURL(img));
      setFileName(img.name);
    }
  }
  return (
    <div className="container mt-4 mb-4">
      <label className="h5 color-one">Personal Details</label>
      <div className="row justify-content-center">
        <div className="col-lg-2 justify-content-center d-flex flex-column">
          <div className="form-element">
            <input
              onChange={(e) => img_pathUrl(e.target.files[0])}
              className="position-absolute d-block"
              type="file"
            />
            <label htmlFor="" id="">
              <img src={imageUrl} width="200" height="200" className="" />
              <div>
                <span>{fileName != "" ? fileName : "Choose photo"}</span>
              </div>
            </label>
          </div>
        </div>
        <div className="col-lg-10">
          <div class="form-group">
            <label for="">Full Name</label>
            <input
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              type="text"
              class="form-control"
              placeholder="Full Name"
              required
            />
          </div>
          <div class="form-group">
            <label for="">Phone Number</label>
            <input
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              type="text"
              class="form-control"
              placeholder="Phone Number"
              required
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="">Email</label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          class="form-control"
          placeholder="Email"
          required
        />
      </div>
      <div class="form-group">
        <label for="">Username</label>
        <input
          value={userName}
          disabled
          type="text"
          class="form-control"
          placeholder="Username"
          required
        />
      </div>
      <div class="form-group">
        <label for="">NetworkId</label>
        <input
          value={"BZ" +networkId}
          disabled
          type="text"
          class="form-control"
          placeholder="Network Id"
          required
        />
      </div>
      <div class="form-group">
        <label for="">Sponsor Id</label>
        <input
          value={sponsorId != 0 ? "BZ" + sponsorId : ""}
          disabled
          type="text"
          class="form-control"
          placeholder="Sponsor Id"
          required
        />
      </div>
      <div class="form-group">
        <label for="">Address</label>
        <input
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          type="text"
          class="form-control"
          placeholder="Address"
          required
        />
      </div>
      <label className="h5 color-one">Bank Details</label>
      <div class="form-group">
        <label for="">Bank Name</label>
        <input
          value={bank}
          disabled={isKYC}
          onChange={(e) => setBank(e.target.value)}
          type="text"
          class="form-control"
          placeholder="Bank Name"
          required
        />
      </div>
      <div class="form-group">
        <label for="">Account Number</label>
        <input
          value={account}
          disabled={isKYC}
          onChange={(e) => setAccount(e.target.value)}
          type="text"
          class="form-control"
          placeholder="Account Number"
          required
        />
      </div>
      <div class="form-group">
        <label for="">IFS Code</label>
        <input
          value={ifsc}
          disabled={isKYC}
          onChange={(e) => setIFSC(e.target.value)}
          type="text"
          class="form-control"
          placeholder="IFS Code"
          required
        />
      </div>
      <div class="form-group">
        <label for="">Bank Proof</label>
        <input
          onChange={(e) => setProof(e.target.files[0])}
          disabled={isKYC}
          type="file"
          class="form-control"
          placeholder="Bank Proof"
          required
        />
        {proofUrl != null && proofUrl != "" ? (
          <a
            target="_blank"
            className="color-two"
            href={"/Thumbnails/" + proofUrl}
          >
            View
          </a>
        ) : null}
      </div>
      <div class="form-group">
        <label for="">Id Proof</label>
        <input
          onChange={(e) => setIdProof(e.target.files[0])}
          disabled={isKYC}
          type="file"
          class="form-control"
          placeholder="Id Proof"
          required
        />
        {idProofUrl != null && idProofUrl != "" ? (
          <a
            target="_blank"
            className="color-two"
            href={"/Thumbnails/" + idProofUrl}
          >
            View
          </a>
        ) : null}
      </div>
      <div class="form-group">
        <label for="">Pan Card</label>
        <input
          onChange={(e) => setPanProof(e.target.files[0])}
          disabled={isKYC}
          type="file"
          class="form-control"
          placeholder="Pan Card"
          required
        />
        {panProofUrl != null && panProofUrl != "" ? (
          <a
            target="_blank"
            className="color-two"
            href={"/Thumbnails/" + panProofUrl}
          >
            View
          </a>
        ) : null}
        <div class="form-group">
          <label for="">KYC Status</label>
          <input
            value={isKYC === true ? "Active" : "Pending"}
            disabled={true}
            type="text"
            class="form-control"
            placeholder="KYC Status"
          />
        </div>
        <span style={isKYC === true ? { color: "green" } : { color: "red" }}>
          {isKYC != true
            ? "Your kyc is pending you will be charged 20 % TDS or if you will update kyc you will be charged 10 % TDS"
            : "You will be charged 10 % TDS"}
        </span>
      </div>
      <label className="h5 color-one">
        Change Password (Enter password details if you wish to change password)
      </label>
      <div class="form-group">
        <label for="">Old Password</label>
        <input
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          type="password"
          class="form-control"
          placeholder="Old Password"
        />
      </div>
      <div class="form-group">
        <label for="">New Password</label>
        <input
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          type="password"
          class="form-control"
          placeholder="New Password"
        />
      </div>
      <button
        onClick={() => UpdateProfile()}
        type="button"
        className="btn btn-primary text-white color-bg-one color-border-one button-hover"
      >
        Save
      </button>
    </div>
  );
}
