import { cibRubygems } from "@coreui/icons";
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
function Preloader() {
  return (
    <div
      style={{ zIndex: 99999 }}
      className="d-flex justify-content-center align-items-center h-100 w-100 position-fixed bg-white"
    >
      <Spinner animation="border" variant="danger" />
    </div>
  );
}

export default Preloader;
export function Itemloader() {
  return (
    <div
      style={{ zIndex: 99999, top:0, }}
      className="d-flex justify-content-center align-items-center h-100 w-100 position-fixed bg-white"
    >
      <Spinner animation="border" variant="danger" />
    </div>
  );
}
