import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { logo } from "src/assets/icons/logo";
import { ApiFetch } from "../assets/FetchApi";

export default function ForgotPassword() {
  const history = useHistory();
  const [username, setUsername] = useState("");
  function forgotPassword() {
    ApiFetch("Account/GeneratePasswordResetToken?username=" + username).then(
      (res) => {
        if (res.status === 200) {
          history.push("ResetPassword", { username: username });
        } else if (res.status === 406) {
          res.json().then((result) => {
            history.push("ConfirmAccount", { username: result });
          });
        }
        else{
          alert("Something went wrong, please try again later")
        }
      }
    );
  }
  return (
    <div className="mt-5 mb-5">
      <div className="container">
        <div className="justify-content-center align-items-center d-flex flex-column w-100">
          <div className="">
            {" "}
            <img width={120} src={logo} />{" "}
          </div>
          <div className="col-6">
            <form
              className="form-validation"
              name="forgot_password"
              autocomplete="off"
            >
              <div className="list-group form-group login-input">
                <div className="list-group-item form-group">
                  <input
                    type="email"
                    onChange={(e) => setUsername(e.target.value)}
                    id="e_mail"
                    name="e_mail"
                    placeholder="Username / Network Id"
                    className="form-control no-border"
                  />
                </div>
              </div>
              <div className="">
                <input
                  disabled={username != "" ? false : true}
                  type="button"
                  onClick={() => forgotPassword()}
                  name="forgot_password_submit"
                  className="btn btn-lg color-bg-one text-white button-hover btn-block"
                  value="Send Request"
                />
              </div>
            </form>
          </div>
          <div className="text-center text-center p-2">
            <Link className="p-2" to="/Login">
              <i className="fa fa-long-arrow-left" aria-hidden="true"></i> Login
            </Link>
          </div>

          <div
            className="text-center"
            ng-include="'tpl/blocks/page_footer.html'"
          ></div>
        </div>
      </div>
    </div>
  );
}
