import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "src/context";
import { ApiFetch } from "../assets/FetchApi";
export default function CheckOut() {
  const history = useHistory();
  const { appUser } = useContext(AppContext);
  const [cartItems, setCartItem] = useState([]);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [type, setType] = useState("");
  const [area, setArea] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [tax, setTax] = useState(0);
  function cartList() {
    ApiFetch("User/OrderList", appUser.token).then((res) => {
      if (res.status === 200) {
        res.json().then((result) => {
          setCartItem(result.cart);
          setSubTotal(result.subTotal);
          setTax(result.tax);
          setTotal(result.total);
          if (result.address != null) {
            setFullName(result.address.fullName);
            setPhoneNumber(result.address.phoneNumber);
            setArea(result.address.area);
            setCity(result.address.city);
            setState(result.address.state);
            setLandmark(result.address.landmark);
            setPinCode(result.address.pinCode);
          }
        });
      }
    });
  }
  function placeOrder() {
    ApiFetch("User/CreateOrder", appUser.token, {
      FullName: fullName,
      PhoneNumber: phoneNumber,
      City: city,
      State: state,
      Landmark: landmark,
      PinCode: pinCode,
      Area: area,
    }).then((res) => {
      if (res.status === 200) {
        cartList();
        history.push("/");
      }
    });
  }
  useEffect(() => {
    cartList();
  }, []);
  function removeFromCart(id) {
    ApiFetch("User/RemoveFromCart?id=" + id, appUser.token).then((res) => {
      if (res.status === 200) {
        cartList();
      }
    });
  }
  return (
    <div className="container mt-4 mb-4">
      <div className="row">
        <div className="col-lg-8">
          <Table>
            <thead>
              <tr>
                <td>
                  <h5 className="ml-5">Product</h5>
                </td>
                <td>
                  <h5 className="">Quantity</h5>
                </td>
                <td>
                  <h5 className="">Amount</h5>
                </td>
              </tr>
            </thead>
            <tbody>
              {cartItems.length > 0 ? (
                cartItems.map((item) => (
                  <tr>
                    <td>
                      <div className="row align-items-center">
                        <div className="mr-3">
                          <img
                            src={"/Thumbnails/" + item.products.imageUrl}
                            width="150"
                          />
                        </div>
                        <div>
                          <div>{item.products.name}</div>
                          <div>₹ {item.products.price} /-</div>
                          <button
                            onClick={() => removeFromCart(item.id)}
                            className="bg-transparent color-one border-0 p-0"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </td>
                    <td className="">
                      <div className="mt-4">{item.quantity}</div>
                    </td>
                    <td className="">
                      <div className="mt-4">{item.amount}</div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td></td>
                  <td className="text-center text-danger">
                    No items in the cart list
                  </td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
          <button
            onClick={() => history.push("/Products")}
            className="btn btn-primary text-white color-bg-one button-hover color-border-one mb-3"
          >
            Continue for shopping
          </button>
          <div class="border col-6 ml-auto color-border-one mb-3"></div>
          <div className="ml-auto col-6">
            <div className="row mr-lg-4 mb-3">
              <div className="h6">Sub Total:</div>
              <div className="ml-auto mr-lg-5 pr-lg-4">₹ {subTotal} /-</div>
            </div>

            <div className="row mr-lg-4 mb-3">
              <div className="h6">Tax:</div>
              <div className="ml-auto mr-lg-5 pr-lg-4">₹ {tax} /-</div>
            </div>

            <div className="row mr-lg-4 mb-3">
              <div className="h6">20% Purchase discount:</div>
              <div className="ml-auto mr-lg-5 pr-lg-4">
                ₹ {(subTotal / 100) * 20} /-
              </div>
            </div>

            <div className="row mr-lg-4">
              <div className="h6">Total:</div>
              <div className="ml-auto mr-lg-5 pr-lg-4">
                ₹ {total - (subTotal / 100) * 20} /-
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div class="form-group">
            <label for="">Full Name</label>
            <input
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              type="text"
              class="form-control"
              placeholder="Enter Full Name"
              required
            />
          </div>
          <div class="form-group">
            <label for="">Phone Number</label>
            <input
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              type="text"
              class="form-control"
              placeholder="Enter Phone Number"
              required
            />
          </div>
          <div class="form-group">
            <label for="">Address 1</label>
            <input
              value={area}
              onChange={(e) => setArea(e.target.value)}
              type="text"
              class="form-control"
              placeholder="Enter Address 1"
              required
            />
          </div>
          <div class="form-group">
            <label for="">City</label>
            <input
              value={city}
              onChange={(e) => setCity(e.target.value)}
              type="text"
              class="form-control"
              placeholder="Enter City"
              required
            />
          </div>
          <div class="form-group">
            <label for="">State</label>
            <input
              value={state}
              onChange={(e) => setState(e.target.value)}
              type="text"
              class="form-control"
              placeholder="Enter State"
              required
            />
          </div>
          <div class="form-group">
            <label for="">Landmark</label>
            <input
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
              type="text"
              class="form-control"
              placeholder="Enter Landmark"
              required
            />
          </div>
          <div class="form-group">
            <label for="">Pincode</label>
            <input
              value={pinCode}
              onChange={(e) => setPinCode(e.target.value)}
              type="text"
              class="form-control"
              placeholder="Enter Pincode"
              required
            />
          </div>
          <button
            disabled={total != "" ? false : true}
            onClick={() => placeOrder()}
            type="button"
            className="btn btn-primary text-white color-bg-one color-border-one button-hover"
          >
            Place Order
          </button>
        </div>
      </div>
    </div>
  );
}
