import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, Button } from "react-bootstrap";
import { ApiFetch } from "../assets/FetchApi";
import { AppContext } from "src/context";
import Preloader, { Itemloader } from "src/Preloader";
const Products = ({ location }) => {
  const { state } = location;
  const history = useHistory();
  const { appUser } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const loadData = (id) => {
    ApiFetch("User/Products?id=" + id, appUser.token)
      .then((res) => {
        if (res.status === 200) {
          res.json().then((result) => {
            setData(result.products);
            setCategories(result.categories);
          });
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (state != undefined) {
      loadData(state.id);
    } else {
      loadData(categoryId);
    }
  }, []);

  return (
    <div className="mt-4">
      {isLoading ? <Itemloader /> : null}
      <div class="container row m-auto pl-2 pr-2">
        <div className="col-lg-3 mt-4" data-aos="fade-left">
          <ul className="list-unstyled li-gap">
            <li>
              <Link
                className="h5"
                onClick={() => {
                  setCategoryId(0);
                  loadData(0);
                }}
              >
                All Products
              </Link>
            </li>
            {categories.map((item) => (
              <li>
                <Link
                  onClick={() => {
                    setCategoryId(item.id);
                    loadData(item.id);
                  }}
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-lg-9" data-aos="zoom-in">
          <h4 class="page-title">Our Brand</h4>
          <div className="justify-content-center d-flex m-auto">
            <div className="row justify-content-evenly gap col-12">
              {data.map((item) => (
                <Card style={{ width: "15rem" }}>
                  <div className="align-items-center justify-content-center d-flex">
                    <Card.Img
                      variant="top"
                      src={"/Thumbnails/" + item.imageUrl}
                      className="p-3 w-auto"
                      height={150}
                    />
                  </div>
                  <Card.Body>
                    <Card.Title className="h6">{item.name}</Card.Title>
                    <div>
                      <span>MRP: ₹ {item.mrp}</span>
                    </div>
                    <div>
                      <span>Saling Price: ₹ {item.price} </span>
                    </div>
                    <div>
                      <span>BV: {item.bv}</span>
                    </div>
                    <div className="mt-2">
                      <Button
                        className="color-bg-one color-border-one button-hover"
                        onClick={() =>
                          history.push("ProductDetail", { id: item.id })
                        }
                      >
                        View More
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Products;
