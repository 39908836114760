export const ApiFetch = async (url, token, data) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  };
  return fetch("/api/" + url, options);
};
export const FormApiFetch = async (url, token, formData) => {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    body: formData,
  };
  return fetch("/api/" + url, options);
};
export default null;
