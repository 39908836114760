import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Carousel, Card, Button } from "react-bootstrap";
import CarouselMulti from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import image1 from "../assets/Image/slider/1.jpg";
import image2 from "../assets/Image/slider/2.jpg";
import image3 from "../assets/Image/slider/3.jpg";
import { ApiFetch } from "../assets/FetchApi";
const Home = () => {
  const history = useHistory();
  const [topProducts, setTopProducts] = useState([]);
  const [regProducts, setRegProducts] = useState([]);
  const [slider, setSlider] = useState([]);
  var i = 0;
  const loadData = () => {
    ApiFetch("User/HomeItems")
      .then((res) => {
        if (res.status === 200) {
          res.json().then((result) => {
            setTopProducts(result.topProducts);
            setRegProducts(result.regProducts);
            setSlider(result.slider);
          });
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    loadData();
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3.5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div>
      <Carousel
        prevIcon={
          <div className="p-2 pl-3 pr-3 color-bg-one rounded-circle">
            <FontAwesomeIcon
              icon="angle-left"
              style={{ fontSize: 35 }}
              color="white"
            />
          </div>
        }
        nextIcon={
          <div className="p-2 pl-3 pr-3 color-bg-one rounded-circle">
            <FontAwesomeIcon
              icon="angle-right"
              style={{ fontSize: 35 }}
              color="white"
            />
          </div>
        }
      >
        {slider.map((item) => (
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={"/Sliders/" + item.imageUrl}
              alt="Slider"
              style={{ height: 400 }}
            />
            {/* <Carousel.Caption>
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption> */}
          </Carousel.Item>
        ))}
      </Carousel>
      <section className="justify-content-center d-flex">
        <div
          className="container mt-5 pl-1 pr-1 align-selt-center align-items-center justify-content-center"
          data-aos="slide-left"
        >
          <h3 className="color-one mb-3">Top Categories</h3>
          <CarouselMulti
            showDots={true}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={1500}
            keyBoardControl={true}
          >
            {topProducts.map((item) => (
              <Card style={{ width: "18rem" }}>
                <div className="align-items-center justify-content-center d-flex">
                  <Card.Img
                    className="p-3 w-auto"
                    variant="top"
                    src={"/Thumbnails/" + item.imageUrl}
                    height={150}
                  />
                </div>
                <Card.Body>
                  <Card.Title>{item.name}</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the Product Title and
                    make up the bulk of the card's content.
                  </Card.Text>
                  <Button
                    onClick={() => {
                      history.push("/ProductDetail", { id: item.id });
                    }}
                    className="color-bg-one  color-border-one button-hover"
                  >
                    View More
                  </Button>
                </Card.Body>
              </Card>
            ))}
          </CarouselMulti>
        </div>
      </section>
      <section className="justify-content-center d-flex">
        <div
          className="container mt-5 pl-1 pr-1 align-selt-center align-items-center justify-content-center"
          data-aos="slide-right"
        >
          <h3 className="color-one mb-3">Registration Products</h3>
          <CarouselMulti
            className="mb-5"
            showDots={true}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={1500}
            keyBoardControl={true}
          >
            {regProducts.map((item) => (
              <Card style={{ width: "18rem" }}>
                <div className="align-items-center justify-content-center d-flex">
                  <Card.Img
                    className="p-3 w-auto"
                    variant="top"
                    src={"/Thumbnails/" + item.imageUrl}
                    height={150}
                  />
                </div>
                <Card.Body>
                  <Card.Title>{item.name}</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the Product Title and
                    make up the bulk of the card's content.
                  </Card.Text>
                  <Button
                    onClick={() => {
                      history.push("/ProductDetail", { id: item.id });
                    }}
                    className="color-bg-one  color-border-one button-hover"
                  >
                    View More
                  </Button>
                </Card.Body>
              </Card>
            ))}
          </CarouselMulti>
        </div>
      </section>
    </div>
  );
};
export default Home;
