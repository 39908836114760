import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { logo } from "src/assets/icons/logo";
import { AppContext } from "src/context";
import { ApiFetch } from "../assets/FetchApi";
const Login = () => {
  const { appUser, setAppUser } = useContext(AppContext);
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = () => {
    ApiFetch("Account/Login", null, {
      UserName: username.replace("BZ", ""),
      Password: password,
    })
      .then((res) => {
        if (res.status == 200) {
          res.json().then((result) => {
            setAppUser({
              ...appUser,
              isLoggedIn: true,
              token: result.token,
              user: result.userName,
              role: result.designation,
              designation: result.designation,
              fullName: result.fullName,
              networkId: result.networkId,
            });
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("token", result.token);
            localStorage.setItem("username", result.userName);
            localStorage.setItem("role", result.designation);
            localStorage.setItem("designation", result.designation);
            localStorage.setItem("fullName", result.fullName);
            localStorage.setItem("networkId", result.networkId);
            if (result.designation == "Admin") {
              history.push("/#/dashboard");
            } else {
              history.push("/");
            }
          });
        } else if (res.status === 406) {
          res.json().then((result) => {
            history.push("ConfirmAccount", { username: result });
          });
        } else {
          alert("Login Failed, try with correct details (Username & password)");
        }
      })
      .catch((error) => {});
  };
  return (
    <div className="mt-5 mb-5">
      <div className="container">
        <div className="justify-content-center align-items-center d-flex flex-column w-100">
          <div className="">
            {" "}
            <img width={120} src={logo} />{" "}
          </div>
          <div className="col-6">
            <form
              className=""
              name="loginform"
              autocomplete="off"
              method="post"
              accept-charset="utf-8"
              novalidate="novalidate"
            >
              {/* <input type="hidden" name="inf_token" />

                  <input type="password"  />
                  <input type="text"  /> */}
              <div className="text-danger  text-center" ng-show="authError">
                {" "}
              </div>

              <div className="list-group form-group login-input">
                <div className="list-group-item">
                  <input
                    type="text"
                    name="admin_username"
                    id="admin_usernme"
                    autocomplete="Off"
                    onChange={(val) => setUsername(val.target.value)}
                    placeholder="Username / Network Id"
                    className="form-control no-border"
                  />
                </div>
                <div className="list-group-item form-group">
                  <input
                    onChange={(val) => setPassword(val.target.value)}
                    type="password"
                    name="admin_password"
                    id="admin_password"
                    placeholder="Password"
                    className="form-control no-border password"
                  />
                </div>
              </div>
              <div className="text-left pt-3 pb-3">
                <Link to="/ForgotPassword">Forgot Password?</Link>
              </div>
              <div className="">
                <input
                  type="button"
                  name="login_btn"
                  value="Login"
                  onClick={handleLogin}
                  className="btn btn-lg color-bg-one text-white button-hover btn-block"
                />
              </div>

              <div className="m-t-md">
                <span id="loginmsg"></span>
              </div>
              <p className="text-center p-3 link-fade">
                <small>Don't have an account? </small>{" "}
                <Link className="register info-link" to="/Register">
                  Sign Up
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
