import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { logo } from "src/assets/icons/logo";
import { ApiFetch } from "../assets/FetchApi";

export default function ResetPassword({ location }) {
  const { state } = location;
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [Otp, setOtp] = useState("");
  useEffect(() => {
    setUsername(state.username);
  }, []);
  function resetPassword() {
    ApiFetch(
      "Account/ResetPassword?username=" +
        username +
        "&NewPassword=" +
        newPassword +
        "&Otp=" +
        Otp
    ).then((res) => {
      if (res.status === 200) {
        history.push("Login");
      }
    });
  }
  return (
    <div className="mt-5 mb-5">
      <div className="container">
        <div className="justify-content-center align-items-center d-flex flex-column w-100">
          <div className="">
            {" "}
            <img width={120} src={logo} />{" "}
          </div>
          <div className="col-6">
            <form className="form-validation" autocomplete="off">
              <div className="list-group form-group login-input">
                <div className="list-group-item form-group">
                  <input
                    onChange={(e) => setOtp(e.target.value)}
                    type="text"
                    placeholder="Enter OTP"
                    className="form-control no-border"
                  />
                </div>
                <div className="list-group-item form-group">
                  <input
                    onChange={(e) => setNewPassword(e.target.value)}
                    type="text"
                    placeholder="New Password"
                    className="form-control no-border"
                  />
                </div>
                <div className="list-group-item form-group">
                  <input
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type="text"
                    placeholder="Confirm Password"
                    className="form-control no-border"
                  />
                </div>
              </div>
              <div className="">
                <input
                  disabled={
                    newPassword === confirmPassword &&
                    newPassword != "" &&
                    Otp != ""
                      ? false
                      : true
                  }
                  type="button"
                  onClick={() => resetPassword()}
                  className="btn btn-lg color-bg-one text-white button-hover btn-block"
                  value="Reset Password"
                />
              </div>
            </form>
          </div>
          <div className="text-center text-center p-2">
            <Link className="p-2" to="/Login">
              <i className="fa fa-long-arrow-left" aria-hidden="true"></i> Login
            </Link>
          </div>

          <div
            className="text-center"
            ng-include="'tpl/blocks/page_footer.html'"
          ></div>
        </div>
      </div>
    </div>
  );
}
