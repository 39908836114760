import React, { useContext, useState, useEffect } from "react";
import $ from "jquery";
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/style.scss";
import { Navbar, Nav, NavDropdown, Dropdown, Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import logo from "../assets/Image/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppContext } from "src/context";
import { ApiFetch } from "../assets/FetchApi";
export function NavMenu() {
  const { appUser, setAppUser } = useContext(AppContext);
  const history = useHistory();
  const [cartItem, setCartItem] = useState([]);
  const [categories, setCategories] = useState([]);
  function SignOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("username");
    localStorage.removeItem("clientArea");
    history.push("/");
    setAppUser({});
  }
  function cartList() {
    ApiFetch("User/CartList", appUser.token).then((res) => {
      if (res.status === 200) {
        res.json().then((result) => {
          setCartItem(result);
        });
      }
    });
    ApiFetch("User/Categories", appUser.token).then((res) => {
      if (res.status === 200) {
        res.json().then((result) => {
          setCategories(result);
        });
      }
    });
  }
  useEffect(() => {
    cartList();
  }, []);
  function removeFromCart(id) {
    ApiFetch("User/RemoveFromCart?id=" + id, appUser.token).then((res) => {
      if (res.status === 200) {
        cartList();
      }
    });
  }
  function userDashboard() {
    localStorage.setItem("clientArea", true);
    setAppUser({
      ...appUser,
      token: localStorage.getItem("token"),
      isLoggedIn: localStorage.getItem("isLoggedIn"),
      user: localStorage.getItem("username"),
      role: localStorage.getItem("role"),
      clientArea: localStorage.getItem("clientArea"),
    });
  }
  $(".checkoutclick").on("click", function () {
    $("#dropdown-basic").parent("div").children("div").removeClass("show");
  });
  $("#dropdown-basic").on("click", function () {
    cartList();
  });
  onscroll = () => {
    if (document.getElementById("navbarTop")) {
      const height = document.getElementById("navbarTop").clientHeight;
      const scrollHeight = window.pageYOffset;
      const width = window.innerWidth;
      if (scrollHeight > height && width > 768) {
        document
          .getElementById("navbarTop")
          .classList.add("fixed-top", "shadow");
      } else {
        document
          .getElementById("navbarTop")
          .classList.remove("fixed-top", "shadow");
      }
    }
  };
  return (
    <div className="d-flex shadow flex-column">
      <div className="p-2 border-top border-bottom bg-light header-top">
        <div className="row pl-4 pr-2 w-100 justify-content-center align-items-center">
          <img src={logo} width={100} className="ml-4" />
          <div className="ml-auto mt-lg-0 mt-2 col-lg-6 col-12 mr-lg-4 d-flex justify-content-center align-items-center">
            <input
              type="text"
              style={{ borderRadius: 50 }}
              placeholder="Search Product and Brands"
              className="form-control p-4 col-12"
            />
            <Link className="color-bg-one p-2 search-icon rounded-circle">
              <FontAwesomeIcon icon="search" className="" color="white" />
            </Link>
          </div>
          <div>
            <Dropdown>
              <Dropdown.Toggle
                className="p-2 outline-none bg-transparent border-0 my-cart-btn outline-none text-dark"
                id="dropdown-basic"
              >
                <FontAwesomeIcon icon="cart-plus" className="mr-1" />
                My Cart
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {appUser.token != null ? (
                  cartItem.length == 0 ? (
                    <Dropdown.Item className="pl-2 pr-2 bg-transparent d-flex">
                      No items
                    </Dropdown.Item>
                  ) : (
                    <>
                      <Table size="sm" responsive="lg">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product Name</th>
                            <th>Amount</th>
                            <th>Quantity</th>
                            {/* <th>Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {cartItem.map((item) => (
                            <tr>
                              <td>{cartItem.indexOf(item) + 1}</td>
                              <td>{item.products.name}</td>
                              <td>{item.amount}</td>
                              <td>{item.quantity}</td>
                              {/* <td>
                                <button
                                  className="border-0 bg-transparent"
                                  onClick={() => removeFromCart(item.id)}
                                >
                                  <FontAwesomeIcon icon="trash" color="red" />
                                </button>
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="d-flex justify-content-end pr-2">
                        <Link
                          to="/CheckOut"
                          className="ml-2 checkoutclick px-2 py-1 color-bg-one text-white button-hover border-0 rounded"
                        >
                          Checkout
                        </Link>
                      </div>
                    </>
                  )
                ) : (
                  <div className="d-flex justify-content-center pr-2">
                    <Link
                      to="/Login"
                      className="ml-2 px-2 py-1 color-bg-one text-white button-hover border-0 rounded"
                    >
                      Login
                    </Link>
                  </div>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="ml-4 mr-1">
            <Dropdown>
              <Dropdown.Toggle
                className="p-0 bg-transparent border-0 text-dark button-outline-none"
                variant="success"
                id="dropdown-basic"
              >
                <FontAwesomeIcon icon="user" className="mr-1" />
                My Account
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {appUser.token != null ? (
                  <>
                    <Dropdown.Item className="p-0 bg-transparent d-flex">
                      <Link to="/MyAccount" className="pl-2 pr-2 p-1 w-100">
                        My Profile
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item className="p-0 bg-transparent d-flex">
                      <Link
                        to="#"
                        onClick={() => userDashboard()}
                        className="pl-2 pr-2 p-1 w-100"
                      >
                        More Details
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item className="p-0 bg-transparent d-flex">
                      <Link
                        onClick={SignOut}
                        to="/Login"
                        className="pl-2 pr-2 p-1 w-100"
                      >
                        Logout
                      </Link>
                    </Dropdown.Item>
                  </>
                ) : (
                  <>
                    <Dropdown.Item className="p-0 bg-transparent d-flex">
                      <Link to="/Login" className="pl-2 pr-2 p-1 w-100">
                        Login
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item className="p-0 bg-transparent d-flex">
                      <Link to="/Register" className="pl-2 pr-2 p-1 w-100">
                        Register
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item className="p-0 bg-transparent d-flex">
                      <Link
                        to="/ForgotPassword"
                        className="pl-2 pr-2 p-1 w-100"
                      >
                        Forgot Password
                      </Link>
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="border bg-light">
        <marquee><span className="text-danger">5% of profit will be used for educational donation...</span></marquee>
      </div>
      <div
        className="container p-3 p-lg-0 mw-100 m-0 d-flex row bg-white"
        id="navbarTop"
      >
        <div className="custom-100 pl-3 pr-3">
          <Navbar bg="transparent" className="w-100 pt-0 pb-0" expand="lg">
            <Navbar.Toggle className="" aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="d-lg-flex w-100" id="basic-navbar-nav">
              <Nav className="align-items-lg-center p-0 w-100 gap">
                <Nav.Item className="">
                  <NavDropdown
                    title="Shop by Category"
                    className="inner-a d-none d-lg-block p-1 text-white color-bg-one button-hover rounded m-0"
                    id="basic-nav-dropdown"
                  >
                    {categories.map((item) => (
                      <NavDropdown.Item
                        onClick={() =>
                          history.push("Products", { id: item.id })
                        }
                        href="#"
                      >
                        {item.name}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                </Nav.Item>
                <Nav.Link className="p-0">
                  <Link className="p-2" to="/">
                    Home
                  </Link>
                </Nav.Link>
                <Nav.Link className="p-0">
                  <Link className="p-2" to="/Products">
                    Products
                  </Link>
                </Nav.Link>
                <Nav.Link className="p-0">
                  <Link className="p-2" to="/AboutUs">
                    About Us
                  </Link>
                </Nav.Link>
                <Nav.Link className="p-0">
                  <Link className="p-2" to="/ContactUs">
                    Contact Us
                  </Link>
                </Nav.Link>
                <a className="ml-auto" href="tel:+91-9468199599">
                  <Nav.Item className="ml-3 row color-bg-one p-2 button-hover align-items-center rounded">
                    <FontAwesomeIcon
                      className="text-white mr-1 align-self-center"
                      icon="phone-alt"
                    />
                    <span className="mr-1 text-white">Call Us:</span>
                    <span className="text-white">+91-9468199599</span>
                  </Nav.Item>
                </a>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </div>
  );
}
