import React, { useState, useEffect } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import { AppContext } from "src/context";
import { Layout } from "./components/UserComponent/Layout";
import Preloader from "src/Preloader";
import Home from "./components/UserComponent/Home";
import { Sitemap } from "./components/UserComponent/Sitemap";
import { ContactUs } from "./components/UserComponent/ContactUs";
import Products from "./components/UserComponent/Products";
import { AboutUs } from "./components/UserComponent/AboutUs";
import UserLogin from "./components/UserComponent/Login";
import ForgotPassword from "./components/UserComponent/ForgotPassword";
import ResetPassword from "./components/UserComponent/ResetPassword";
import ConfirmAccount from "./components/UserComponent/ConfirmAccount";
import UserRegister from "./components/UserComponent/Register";
import ProductDetail from "./components/UserComponent/ProductDetail";
import CheckOut from "./components/UserComponent/CheckOut";
import Profile from "./components/UserComponent/Profile";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faCoffee,
  fas,
} from "@fortawesome/free-solid-svg-icons";
import { ApiFetch } from "./components/assets/FetchApi";
const App = () => {
  const [appUser, setAppUser] = useState({
    token: localStorage.getItem("token"),
    isLoggedIn: localStorage.getItem("isLoggedIn"),
    user: localStorage.getItem("username"),
    role: localStorage.getItem("role"),
    fullName: localStorage.getItem("fullName"),
    networkId: localStorage.getItem("networkId"),
    clientArea: localStorage.getItem("clientArea"),
  });
  library.add(fab, faCheckSquare, faCoffee, fas);
  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  // Containers
  const TheLayout = React.lazy(() => import("./containers/TheLayout"));
  const TheUserLayout = React.lazy(() =>
    import("./components/UserDashboard/containers/TheLayout")
  );
  const checkUser = () => {
    ApiFetch("Account/CheckUser?username=" + appUser.user, appUser.token).then(
      (res) => {
        if (res.status === 200) {
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("isLoggedIn");
          localStorage.removeItem("username");
          localStorage.removeItem("role");
          localStorage.removeItem("fullName");
          localStorage.removeItem("networkId");
          localStorage.removeItem("clientArea");
          setAppUser({
            token: null,
            isLoggedIn: false,
            user: null,
            role: null,
            fullName: null,
            networkId: null,
            clientArea: "false",
          });
        }
      }
    );
  };
  const [isLoading, setIsLoading] = useState(true);
  const loader = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };
  useEffect(() => {
    loader();
    checkUser();
  }, []);
  if (isLoading) {
    return <Preloader />;
  }
  return (
    <AppContext.Provider value={{ appUser, setAppUser }}>
      {appUser.role == "Admin" ? (
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </HashRouter>
      ) : appUser.role === "User" && appUser.clientArea === "true" ? (
        <>
          <HashRouter>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <TheUserLayout {...props} />}
                />
              </Switch>
            </React.Suspense>
          </HashRouter>
        </>
      ) : (
        <Layout>
          <Route exact path="/" component={Home} />
          <Route path="/Sitemap" component={Sitemap} />
          <Route path="/ContactUs" component={ContactUs} />
          <Route path="/Products" component={Products} />
          <Route path="/ProductDetail" component={ProductDetail} />
          <Route path="/CheckOut" component={CheckOut} />
          <Route path="/AboutUs" component={AboutUs} />
          <Route path="/Login" component={UserLogin} />
          <Route path="/Register" component={UserRegister} />
          <Route path="/ForgotPassword" component={ForgotPassword} />
          <Route path="/ResetPassword" component={ResetPassword} />
          <Route path="/ConfirmAccount" component={ConfirmAccount} />
          <Route path="/MyAccount" component={Profile} />
        </Layout>
      )}
    </AppContext.Provider>
  );
};

export default App;
